@import '@sass-mixins';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;700&display=swap');

body {
  font-family: 'Inter', sans-serif !important;
}

:root {
  // --font-primary: 'Rajdhani', sans-serif;
  --font-primary: 'Inter', sans-serif;
  --font-secondary: 'Inter', sans-serif;
  --font-header-primary: 'Rajdhani';

  font-family: var(--font-primary) !important;
}

.Toastify {
  &__toast {
    @include roundedBox(#e2e3e9, #f6f6f9);
    position: unset !important;
    background: unset !important;
    background-color: unset !important;

    margin-bottom: 0px;
    font-family: var(--font-primary) !important;
    font-weight: 600 !important;
    line-height: 20px !important;
  }

  &__progress-bar {
    width: 0px;
  }

  &__toast-container {
    padding: 0px !important;
    border-radius: 10px 5px !important;
    overflow: hidden !important;
  }
}