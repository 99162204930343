@import '@sass-mixins';

@keyframes appear {
  to {
    opacity: 1;
  }
}

@keyframes disappear {
  to {
    opacity: 0;
  }
}

@keyframes hideBg {
  to {
    transform: translateY(100%);
  }
}

.wrapper {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  visibility: hidden;
  transition: visibility 0s 0.4s;

  &.isVisible {
    transition: visibility 0s;
    visibility: visible;
  }
}

.bgMask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 0;
  animation: disappear 0.2s 0.2s ease-out forwards;

  .isVisible & {
    animation: none;
  }
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  animation: hideBg 0.2s 0.15s ease-out forwards;

  .isVisible & {
    animation: none;
  }
}

.container {
  position: relative;
  z-index: 2;
  width: 250px;
  animation: disappear 0.15s ease-out forwards;

  @include breakpoint('md-max') {
    width: 200px;
  }

  .isVisible & {
    opacity: 0;
    animation: appear 0.15s 0.25s ease-in forwards;
  }

  svg:nth-child(2) {
    display: none;
  }
}
