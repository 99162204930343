@import '@sass-mixins';

.loading {
  background-color: #0f193e;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30% auto;
  border: 1px solid #1b254c !important;
  border-radius: 10px;

  animation: opacity-pulse 1.2s linear infinite alternate;

  &.round {
    border-radius: 50%;
  }

  :global(.light) & {
    background-color: #f8f8fa;
    border: 1px solid #e7e8ea !important;
  }

  &.loadingBanner {
    background-size: 10% auto;

    @include breakpoint('sm-max') {
      background-size: 30% auto;
    }
  }
}

@keyframes opacity-pulse {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.75;
  }
}
