:root {
  /* Light theme */
  /* Font */
  --theme-font-black: #000000;
  /* Common */
  --theme-main-primary: #ffffff;
  /* Font */
  --theme-font-white: #fff;

  --theme-box-bg: #fff;

  &.dark {
    /* Dark theme */
    /* Font */
    --theme-font-white: #fff;
    /* Common */
    --theme-main-primary: #ffffff;
    /* Font */
    --theme-font-white: #fff;

    --theme-box-bg: #000000;
  }
}