@import '../functions/string';

@mixin layoutContainer($level: 0) {
  margin-left: auto !important;
  margin-right: auto !important;
  box-sizing: border-box;
  padding-left: 80px;
  padding-right: 80px;
  max-width: 1660px;

  @include breakpoint('md-max') {
    padding-left: 50px;
    padding-right: 50px;
  }

  @if $level == 1 {
    padding-left: 150px;
    padding-right: 150px;
  }

  @include breakpoint('sm-max') {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@mixin roundedBox() {
  padding: 16px 22px;
  box-sizing: border-box;
  border-radius: 10px;
  border: var(--theme-box-rounded-box-border);
  background: var(--theme-box-rounded-box);
}

@mixin roundedBox(
  $border-color: #000,
  $background-color: none
) {
  padding: 16px 22px;
  box-sizing: border-box;
  border-radius: 10px;
  border: $border-color;
  background: $background-color;
}

@mixin borderedContainer() {
  border-radius: 5px;
  border: 1px solid var(--theme-common-border-color);
  background: var(--theme-common-container-background);
}

@mixin nftCard() {
  background: var(--theme-card-bg);
  border: var(--theme-card-border);
  border-radius: 20px;
}

@mixin nftCardBanner() {
  background: var(--theme-card-notification-bg);
  border: var(--theme-card-notification-border);
  border-radius: 5px;
}

@mixin picturePlaceholder($w: 24px, $h: 24px) {
  background: var(--theme-placeholder-picture-color);
  border-radius: 50%;
  border: 1px solid #465077;
  height: $w;
  width: $h;
}

/*
* COMMON BORDER
*/

@mixin containerBorderImagePrimary(
  $border-color: #000,
  $background-color: none
) {
  /* *
   * 1. The SVG content needs to be url-escaped for this to work, e.g. "#" gets replaced with "%23"
   * 2. CSS variables won't work ("var(--example-css-variable)"). Only SASS variables or direct values can be used
   * */
  $converted-background-color: str-replace(
    to-string($background-color),
    '#',
    '%23'
  );
  $converted-border-color: str-replace(to-string($border-color), '#', '%23');
  border-image: url('data:image/svg+xml;utf8,<svg width="316" height="55" viewBox="0 0 316 55" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.5667 0.961914C10.2336 0.961914 9.9142 1.09422 9.67869 1.32973L0.367819 10.6406C0.132309 10.8761 0 11.1955 0 11.5286V49.9387C0 52.7129 2.24899 54.9619 5.02326 54.9619H305.433C305.766 54.9619 306.086 54.8296 306.321 54.5941L315.632 45.2832C315.868 45.0477 316 44.7283 316 44.3952V5.98517C316 3.2109 313.751 0.961914 310.977 0.961914H10.5667ZM314.744 38.6363L299.674 53.7061H5.02326C2.94256 53.7061 1.25581 52.0194 1.25581 49.9387V17.2875L8.7907 9.75261L16.3256 2.21773C16.3256 2.21773 308.896 2.21773 310.977 2.21773C313.057 2.21773 314.744 3.90447 314.744 5.98517V38.6363Z" fill="#{$converted-border-color}"/><path d="M299.674 53.7061L314.744 38.6363V5.98517C314.744 3.90447 313.057 2.21773 310.977 2.21773H16.3256L8.7907 9.75261L1.25581 17.2875V49.9387C1.25581 52.0194 2.94256 53.7061 5.02326 53.7061H299.674Z" fill="#{$converted-background-color}"/></svg> ');
}

@mixin containerBorderImageSecondary(
  $border-color: #000,
  $background-color: none
) {
  /* *
   * 1. The SVG content needs to be url-escaped for this to work, e.g. "#" gets replaced with "%23"
   * 2. CSS variables won't work ("var(--example-css-variable)"). Only SASS variables or direct values can be used
   * */
  $converted-background-color: str-replace(
    to-string($background-color),
    '#',
    '%23'
  );
  $converted-border-color: str-replace(to-string($border-color), '#', '%23');
  border-image: url('data:image/svg+xml;utf8,<svg width="314" height="55" viewBox="0 0 316 55" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 49.9387V11.2155L10.2536 1.46191H310.977C313.475 1.46191 315.5 3.48705 315.5 5.98517V44.7083L305.746 54.4619H5.02327C2.52514 54.4619 0.5 52.4368 0.5 49.9387Z" stroke="#{$converted-border-color}" fill="#{$converted-background-color}"/></svg>');
}

@mixin containerBorderHomeSecondaryRight(
  $border-color: #000,
  $background-color: none
) {
  /* *
   * 1. The SVG content needs to be url-escaped for this to work, e.g. "#" gets replaced with "%23"
   * 2. CSS variables won't work ("var(--example-css-variable)"). Only SASS variables or direct values can be used
   * */
  $converted-background-color: str-replace(
    to-string($background-color),
    '#',
    '%23'
  );
  $converted-border-color: str-replace(to-string($border-color), '#', '%23');
  border-image: url('data:image/svg+xml;utf8,<svg width="1360" height="501" viewBox="0 0 1360 501" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 87.2463C0 81.942 2.10714 76.8549 5.85786 73.1042L73.1042 5.85787C76.8549 2.10714 81.942 0 87.2463 0H1364L1360 500.962H30C13.4315 500.962 0 487.531 0 470.962V87.2463Z" fill="#{$converted-background-color}"/><path d="M0.5 87.2463C0.5 82.0746 2.55446 77.1147 6.21142 73.4577L73.4577 6.21142C77.1147 2.55446 82.0746 0.5 87.2463 0.5H1363.5L1359.5 500.462H30C13.7076 500.462 0.5 487.254 0.5 470.962V87.2463Z" stroke="#{$converted-border-color}" stroke-opacity="0.2"/></svg>');
}

@mixin containerBorderHomeSecondaryLeft(
  $border-color: #000,
  $background-color: none
) {
  /* *
   * 1. The SVG content needs to be url-escaped for this to work, e.g. "#" gets replaced with "%23"
   * 2. CSS variables won't work ("var(--example-css-variable)"). Only SASS variables or direct values can be used
   * */
  $converted-background-color: str-replace(
    to-string($background-color),
    '#',
    '%23'
  );
  $converted-border-color: str-replace(to-string($border-color), '#', '%23');
  border-image: url('data:image/svg+xml;utf8,<svg width="1361" height="501" viewBox="0 0 1361 501" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M-0.0379486 0H1331C1347.57 0 1361 13.4315 1361 30V413.716C1361 419.02 1358.89 424.107 1355.14 427.858L1287.9 495.104C1284.15 498.855 1279.06 500.962 1273.75 500.962H0L-0.0379486 0Z" fill="#{$converted-background-color}"/><path d="M0.462089 0.5H1331C1347.29 0.5 1360.5 13.7076 1360.5 30V413.716C1360.5 418.887 1358.45 423.847 1354.79 427.504L1287.54 494.751C1283.89 498.408 1278.93 500.462 1273.75 500.462H0.499962L0.462089 0.5Z" stroke="#{$converted-border-color}" stroke-opacity="0.2"/></svg>');
}

@mixin containerBorderHeaderSecondary(
  $border-color: #000,
  $background-color: none
) {
  /* *
   * 1. The SVG content needs to be url-escaped for this to work, e.g. "#" gets replaced with "%23"
   * 2. CSS variables won't work ("var(--example-css-variable)"). Only SASS variables or direct values can be used
   * */
  $converted-background-color: str-replace(
    to-string($background-color),
    '#',
    '%23'
  );
  $converted-border-color: str-replace(to-string($border-color), '#', '%23');
  border-image: url('data:image/svg+xml;utf8,<svg width="1440" height="501" viewBox="0 0 1440 501" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H1440.04V413.716C1440.04 419.02 1437.93 424.107 1434.18 427.858L1366.93 495.104C1363.18 498.855 1358.1 500.962 1352.79 500.962H0.0379486L0 0Z" fill="#{$converted-background-color}"/><path d="M0.500038 0.5H1439.54V413.716C1439.54 418.887 1437.48 423.847 1433.83 427.504L1366.58 494.751C1362.92 498.408 1357.96 500.462 1352.79 500.462H0.537911L0.500038 0.5Z" stroke="#{$converted-border-color}" stroke-opacity="0.2"/></svg>');
}

@mixin containerBorderHomeFooter($border-color: #000, $background-color: none) {
  /* *
   * 1. The SVG content needs to be url-escaped for this to work, e.g. "#" gets replaced with "%23"
   * 2. CSS variables won't work ("var(--example-css-variable)"). Only SASS variables or direct values can be used
   * */
  $converted-background-color: str-replace(
    to-string($background-color),
    '#',
    '%23'
  );
  $converted-border-color: str-replace(to-string($border-color), '#', '%23');
  border-image: url('data:image/svg+xml;utf8,<svg width="1440" height="360" viewBox="0 0 1440 360" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 87.2463C0 81.942 2.10714 76.8549 5.85786 73.1042L73.1042 5.85787C76.8549 2.10714 81.942 0 87.2463 0H1440V360H0V87.2463Z" fill="#{$converted-background-color}"/><path d="M0.5 87.2463C0.5 82.0746 2.55446 77.1147 6.21142 73.4577L73.4577 6.21142C77.1147 2.55446 82.0746 0.5 87.2463 0.5H1439.5V359.5H0.5V87.2463Z" stroke="#{$converted-border-color}" stroke-opacity="0.2"/></svg>');
}

@mixin containerBorderBlue($border-color: #000, $background-color: none) {
  position: relative;

  & > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    @include containerBorderImagePrimary($border-color, $background-color);
    border-style: solid;
    background-clip: padding-box;
    background-color: $background-color;
    border-width: 40px;
    border-image-slice: 16;

    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}
/*
* TEXT WITH IMAGE BORDER
*/