@import './mixins/.index';

:root {
  font-family: var(--font-primary);
  letter-spacing: -0.02em;
  // font-feature-settings: 'ss04' on, 'ss02' on, 'ss01' on, 'salt' on;
}

body {
  padding-top: var(--header-height);
}

.home {
  body {
    padding: 0px;
  }
}

.scroll-disabled-modal,
.scroll-disabled-menu {
  overflow: hidden;
  overscroll-behavior-y: none;

  body {
    overflow: hidden;
    overscroll-behavior-y: none;
  }
}

.-scroll-theme {
  &::-webkit-scrollbar-thumb {
    background: var(--theme-scrollbar-thumb);
    border-radius: 20px;
  }
}

.coming-soon {
  &:after {
    @include baseText(16px);

    font-size: 8px;
    border-radius: 5px;
    padding: 5px;
    white-space: nowrap;
    background-color: #000;
    color: #fff;
    line-height: 1;

    content: 'Coming soon';
    position: absolute;
    left: 50%;
    transform: translateY(-10px) translateX(-50%);

    top: calc(100% + 5px);

    opacity: 0;
    visibility: hidden;

    transition: all 0.15s ease-in;
  }

  &:hover {
    &:after {
      opacity: 1;
      visibility: visible;
      transform: translateX(-50%) translateY(0);
    }
  }
}
