:root {
  --baseClipPath: polygon(
    8px 0%,
    20% 0%,
    100% 0%,
    100% 82%,
    calc(100% - 8px) 100%,
    0% 100%,
    0% 17px,
    0% 17%
  );
  --squareClipPath: polygon(
    8px 0%,
    10% 0%,
    100% 0%,
    100% calc(100% - 11px),
    calc(100% - 11px) 100%,
    0% 100%,
    0% 12px,
    10px 0%
  );
  --modalClipPath: polygon(
    8px 0%,
    10% 0%,
    100% 0%,
    100% calc(100% - 22px),
    calc(100% - 22px) 100%,
    0% 100%,
    0% 22px,
    22px 0%
  );
}
