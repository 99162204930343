@keyframes wobble {
  from {
    trasnform: scale(1);
  }

  5% {
    transform: scale(1.1);
  }

  10% {
    transform: scale(1.05);
  }

  40% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1.1);
  }

  65% {
    transform: scale(1.2);
  }

  80% {
    transform: scale(1.05);
  }

  to {
    transform: scale(1);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes opacity-pulse {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}
