@import '@sass-mixins';

.header {
  font-family: var(--font-header-primary);
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 999;

  background-color: rgb(255, 255, 255);
  box-shadow: 0 3px 5px rgb(57 63 72 / 30%);

  :global(.dark) & {
    background-color: #FFFFFF;
    box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
  }
}

.container {
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint('md-min') {
    padding: 18px 50px;
  }

  @include breakpoint('lg-min') {
    padding: 18px 80px;
  }
}

.logo {
  position: relative;
  display: flex;
  cursor: pointer;
  z-index: 1;

  @include breakpoint('lg-min') {
    width: 125px;
  }
}

.menu {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include breakpoint('lg-max') {
    left: 45%;
  }
}

.rightBlock {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.banner {
  // background: url('/images/top-banner.png') no-repeat center / cover;
  background-color: #215dd6;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;

  cursor: pointer;
}

.bannerCheck {
  display: block;
  flex-shrink: 0;
  margin-right: 20px;
  width: 12px;
}

.bannerText {
  margin-left: 10px;
}

.bannerArrowRight {
  width: 15px;
  margin-left: 5px;
}

.themeSwitch {
  transition: border 0.15s ease-in;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px;
  margin-right: 8px;
  @include roundedBox(#e2e3e9, #f8f8fa);

  &:before {
    border-width: 15px !important;
    border-image-slice: 20 !important;
  }

  :global(.dark) & {
    @include roundedBox(#364066, #1a244b);
  }

  &:hover {
    opacity: 0.85;
  }
}

.themeSwitchHomePage {
  :global(.light) & {
    @include roundedBox(#364066, #1a244b);
  }
}

.themeIcon {
  color: #0f1528;
  margin-left: 1px;

  :global(.dark) & {
    color: #fff;
  }
}

.themeIconHomePage {
  :global(.light) & {
    color: #fff;
  }
}

.button {
  height: 34px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 0;
  letter-spacing: 0;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_dropdown_opened {}