@mixin baseText($font-size: 14px, $line-height: 17px, $letter-spacing: unset) {
  font-style: normal;
  font-weight: normal;
  font-size: $font-size;
  line-height: $line-height;
  color: var(--theme-font-text-color);
}

@mixin baseTitle($font-size: 36px, $line-height: 44px, $letter-spacing: unset) {
  @include baseText;
  font-style: normal;
  font-weight: 700;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

@mixin baseParagraph(
  $font-size: 16px,
  $line-height: 32px,
  $letter-spacing: -0.02em
) {
  font-style: normal;
  font-weight: 600;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  color: var(--theme-font-paragraph-color);
}

@mixin baseTextFull(
  $font-size: 14px,
  $line-height: 17px,
  $letter-spacing: -0.02em,
  $font-weight: 600,
  $font-family: var(--font-header-primary)
) {
  font-style: normal;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  font-family: $font-family;
  color: var(--theme-font-text-color);
}

@mixin baseTitleFull(
  $font-size: 36px,
  $line-height: 44px,
  $letter-spacing: -0.02em,
  $font-weight: 700,
  $font-family: var(--font-header-primary)
) {
  font-style: normal;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  font-family: $font-family;
  color: var(--theme-font-text-primary);
}

@mixin baseParagraphFull(
  $font-size: 16px,
  $line-height: 32px,
  $letter-spacing: -0.02em,
  $font-weight: 600,
  $font-family: var(--font-header-primary)
) {
  font-style: normal;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  font-family: $font-family;
  color: var(--theme-font-paragraph-color);
}

@mixin baseFontSize {
  font-size: (1/1440) * 100vw;

  @media screen and (max-width: 767px) {
    font-size: (1/375) * 100vw;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: (1/1023) * 100vw;
  }
}
